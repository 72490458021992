.notification-badge {
  color: #ffffff;
  background-color: #ff4081;
  margin: -0.8em -0.8em -0.8em -0.8em;
  border-radius: 50%;
  padding: 5px 5px 5px 5px;
  font-family: 'Roboto';
}

body {
  background-color: #0583f20d;
}

* {
  font-family: 'Poppins', sans-serif;
}


